import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/seo";

export default () => {

    return (<Layout>
        <div id="content" className="animate-fade-in">
            <h1 className={'font-bold'}>Lesenswertes</h1>

            <p> Hier finden Sie eine kleine Auswahl an höchstrichterlichen Entscheidungen auf die ich Sie aufmerksam
                machen möchte.</p>

            <h2 className={'mt-8'}>Vermögensbildung und Unterhalt</h2>

            <p> Haben die Ehegatten zu Zeiten des Zusammenlebens einen Teil des Familieneinkommens zur Vermögensbildung
                verwendet, ist ein diesem Aufwand angemessener Einkommensanteil bei der Berechnung des Unterhalts nicht
                zu berücksichtigen.</p>
            <p>OLG Stuttgart Beschluss vom 20.06.2013 – 16 UF 285/12</p>

            <h2 className={'mt-8'}>Keine vollschichtige Erwerbsobliegenheit bei Kinderbetreuung</h2>

            <p>Die Mutter eines fünfjährigen Kindes ist nicht verpflichtet, die Zeit, in der das Kind betreut wird,
                vollständig für die Erwerbstätigkeit auszunutzen.</p>
            <p>OLG Düsseldorf Beschluss vom 17.12.2013 – II 1 UF 180/13</p>

            <h2 className={'mt-8'}>Behandlung eines Lottogewinns nach der Trennung im Zugewinn</h2>

            <p> Ein vor Rechtshängigkeit des Scheidungsantrags, aber nach der Trennung von einem Ehegatten erzielter
                Lottogewinn fällt in den Zugewinn. Er ist weder dem priviligierten Anfangsvermögen gem. § 1374 Abs. 2
                BGB zuzurechnen noch greift die Härteklausel des § 1381 BGB ein.</p>
            <p>BGH Beschluss vom 16.10.2013- XII ZB 277/12</p>

            <h2 className={'mt-8'}>Keine Testierunfähigkeit bei Behauptung von Demenz „ins Blaue"</h2>

            <p> Fehlt jeglicher Ansatz dafür, dass beim Erblasser eine Demenz ärztlicherseits festgestellt oder er
                diesbezüglich behandelt wurde, ist das Nachlassgericht nicht verpflichtet, von Amts wegen Maßnahmen
                einzuleiten, um eine angebliche Testierunfähigkeit aufzuklären.</p>
            <p>OLG Düsseldorf Beschluss vom 04.11.2013 I-3 Wx 98/13</p>

            <h2 className={'mt-8'}>Erlaubte Untervermietung auch an Touristen?</h2>
            <p> Auch wenn der Vermieter die Untervermietung gleichsam „blanko" gestattet hat, schließt dies nicht
                automatisch die Berechtigung zur Überlassung der Wohnung an häufig wechselnde Touristen ein.</p>
            <p>BGH Urteil vom 08.01.2014 – VIII ZR 210/13</p>

            <h2 className={'mt-8'}>Mieterpflichten bei Erlaubniswiderruf zur Untervermietung</h2>
            <p> Wird eine gemietete Sache untervermietet, steht dem Vermieter, gegebenenfalls nur bei entsprechender
                Vereinbarung, ein Kündigungsrecht zu. Dieses entfällt, wenn der Mieter bereits alle Maßnahmen ergriffen
                hat, um die Untervermietung zu beenden. </p>
            <p>BGH Urteil vom 04.12.2013 -VIII ZR 5/13</p>

            <h2 className={'mt-8'}>Schlüsselrückgabe beim Hauswart und Verjährungsbeginn</h2>
            <p>Für den Beginn der kurzen mietrechtlichen Verjährungsfrist ist nicht nur auf die Besitzaufgabe an den
                Mieträumen abzustellen, sondern auch die entsprechende Kenntnis des Vermieters notwendig. Der BGH folgt
                der Meinung, dass die Übergabe der Schlüssel an dritte Personen nicht zwangsläufig zur Kenntnis des
                Vermieters über die Besitzaufgabe führt. Die in der Praxis geläufige handhabe, den Schlüssel „irgendwo"
                abzugeben, um sich weiteren Ansprüchen zu entziehen, wird daher vielfach scheitern.</p>

            <h2 className={'mt-8'}>Weitere Urteile</h2>

            <p>BGH Urteil vom 20.10.2013 – VIII ZR 402/12</p>
            <p>BVerfG, 1 BvR 1530/11 Zurechnung fiktiver Einkünfte bei der Unterhaltsberechnung.</p>
            <p>BGH XII ZR 65/10 Keine vollschichtige Erwerbsobligenheit bei Betreuung dreier Kinder</p>
            <p>EuGH C-337/10 Verfall von Urlaub bei Langzeiterkrankung</p>
            <p>OLG Oldenburg 13 UF 155/11 Verwirkung des Trennungsunterhalts wegen verfestigter Lebensgemeinschaft</p>
            <p>OLG Hamm 2 UF 215/11 Sekundäre Darlegungslast für ehebedingten Nachteil einer Ehefrau ohne
                Berufsausbildung</p>
            <p>OLG Saarbrücken GUF 20/13 Scheidung auf italienisch? Nein - jetzt auf deutsch!</p>
            <p>OLG Saarbrücken GUF 20/13 Wenn Papa kifft, kann man auch Kindesumgang ohne Übernachtung anordnen.</p>
        </div>
    </Layout>)
};

export const Head = () => {
    return (<SEO title={'Lesenswertes'}/>);
}
